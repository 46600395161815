<template>
  <en-dialog :model-value="modelValue" width="60%" title="折扣审核" @close="$emit('update:model-value', false)">
    <en-form label-position="left" inline>
      <en-form-item label="单据编号">{{ data?.serialNo }}</en-form-item>
      <en-form-item label="客户名称">{{ data?.customer?.name }}</en-form-item>
      <en-form-item label="车牌号">{{ data?.vehicle?.plateNo }}</en-form-item>
      <en-form-item label="工时费">{{ formatMoney(maintenanceFee?.laborHour) }}</en-form-item>
      <en-form-item label="折扣额">{{ formatMoney(maintenanceFee?.discountAmount) }}</en-form-item>
      <en-form-item label="折扣率">{{
        formatPercent(store.attribute.SVCDISRSH.value === 'P' ? maintenanceFee?.discountRate : 1 - (maintenanceFee?.discountRate ?? 0))
      }}</en-form-item>
      <en-form-item label="配件费">{{ formatMoney(goodsFee?.amountBeforeDiscount) }}</en-form-item>
      <en-form-item label="折扣额">{{ formatMoney(goodsFee?.discountAmount) }}</en-form-item>
      <en-form-item label="折扣率">{{
        formatPercent(store.attribute.SVCDISRSH.value === 'P' ? goodsFee?.discountRate : 1 - (goodsFee?.discountRate ?? 0))
      }}</en-form-item>
      <en-form-item label="应收款">{{ formatMoney(serviceFee?.receivableAmount) }}</en-form-item>
    </en-form>

    <en-form :model="form.data" :rules="form.rules" :loading="form.loading" :ref="setRef('form')">
      <en-form-item label="审核人员" prop="auditors">
        <select-maintain
          v-model="form.data.auditors"
          :ajax="{
            action: 'GET /enocloud/common/document/auditor',
            params: (params, name) => (params.payload = { name, type: 'SERVICE_DISCOUNT_RATE' })
          }"
          :props="{
            label: 'user.name',
            value: 'user',
            disabled: (option: EnocloudCommonDefinitions['DocumentAuditorDto']) => option.user?.id === store.user.id
          }"
          multiple
          remote
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="优惠理由">
        <en-input v-model="form.data.comment" type="textarea"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <button-ajax type="primary" :method="footer.submit.click" :disabled="form.loading">确定</button-ajax>
      <button-ajax :disabled="form.loading || !document?.id" :method="footer.cancel.click">取消</button-ajax>
    </template>
  </en-dialog>
</template>

<script lang="ts">
const formDataInit = (): EnocloudServiceDefinitions['DocumentApplicationDto'] => {
  return {
    auditors: [],
    comment: '',
    type: { code: 'SERVICE_DISCOUNT_RATE', message: '', description: '', type: '' },
    params: null
  }
}

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>,
    document: Object,
    maintenanceFee: Object as PropType<{ laborHour: number; discountAmount: number; discountRate: number }>,
    goodsFee: Object as PropType<{ amountBeforeDiscount: number; discountAmount: number; discountRate: number }>,
    serviceFee: Object as PropType<{ receivableAmount: number }>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true,
    cancel: () => true
  },

  watch: {
    modelValue: {
      handler(value) {
        if (!value) this.form.data = formDataInit()
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        rules: {
          auditors: [{ type: 'array', required: true, trigger: 'change', message: '请选择审核人员' }]
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/service/discount',
            loading: true,
            params(params) {
              params.payload = this.form.data
              params.payload.service = this.data
            }
          },
          discard: {
            action: 'PUT /enocloud/service/discount/:documentId/discard',
            params(params) {
              params.paths = [this.document?.id]
            }
          }
        }
      },
      footer: {
        cancel: {
          async click() {
            await this.form.discard()
            this.emit('update:model-value', false)
            this.emit('cancel')
          }
        },
        submit: {
          async click() {
            await this.refs.form.validate()
            await this.form.submit()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
